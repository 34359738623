export const paymentPaidDataPipeline = (payment) => {
    if (!payment) return {};
    const paymentWithPaidData = {
        ...payment,
        paidData: payment.logs && payment.logs[0] && payment.logs[0].data ?
            payment.logs[0].data :
            payment.data ?
                payment.data :
                null
    };
    return paymentWithPaidData;
}